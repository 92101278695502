<script>
  import PNotify from './PNotify.html';

  export default {
    setup (Component) {
      Component.key = 'StyleMaterial';

      // Register the module with PNotify.
      PNotify.modules.StyleMaterial = Component;
      // Prepend this module to the container.
      PNotify.modulesPrependContainer.push(Component);

      if (!PNotify.styling.material) {
        PNotify.styling.material = {};
      }
      PNotify.styling.material = Object.assign(PNotify.styling.material, {
        container: 'pnotify-material',
        notice: 'pnotify-material-notice',
        info: 'pnotify-material-info',
        success: 'pnotify-material-success',
        error: 'pnotify-material-error'
      });

      if (!PNotify.icons.material) {
        PNotify.icons.material = {};
      }
      PNotify.icons.material = Object.assign(PNotify.icons.material, {
        notice: 'material-icons pnotify-material-icon-notice',
        info: 'material-icons pnotify-material-icon-info',
        success: 'material-icons pnotify-material-icon-success',
        error: 'material-icons pnotify-material-icon-error',
        closer: 'material-icons pnotify-material-icon-closer',
        pinUp: 'material-icons pnotify-material-icon-sticker',
        pinDown: 'material-icons pnotify-material-icon-sticker pnotify-material-icon-stuck'
      });
    }
  };
</script>

<style>
  /*
  Color Scheme: https://www.google.com/design/spec/style/color.html#color-color-palette
  Requires stylesheet to work: https://fonts.googleapis.com/css?family=Material+Icons
  */
  :global([ui-pnotify] .pnotify-material) {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    font-size: 14px;
  }
  :global([ui-pnotify] .pnotify-material.ui-pnotify-shadow) {
    -webkit-box-shadow: 0px 6px 24px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 6px 24px 0px rgba(0,0,0,0.2);
    box-shadow: 0px 6px 24px 0px rgba(0,0,0,0.2);
  }
  :global([ui-pnotify] .pnotify-material.ui-pnotify-container) {
    padding: 24px;
  }
  :global([ui-pnotify] .pnotify-material .ui-pnotify-title) {
    font-size: 20px;
    margin-bottom: 20px;
    line-height: 24px;
  }
  :global([ui-pnotify] .pnotify-material .ui-pnotify-title:last-child) {
    margin-bottom: 0;
  }
  :global([ui-pnotify] .pnotify-material .ui-pnotify-text) {
    font-size: 16px;
    line-height: 24px;
  }
  :global([ui-pnotify].ui-pnotify-with-icon .pnotify-material .ui-pnotify-title),
  :global([ui-pnotify].ui-pnotify-with-icon .pnotify-material .ui-pnotify-text),
  :global([ui-pnotify].ui-pnotify-with-icon .pnotify-material .ui-pnotify-confirm) {
    margin-left: 32px;
  }
  :global([dir=rtl] [ui-pnotify].ui-pnotify-with-icon .pnotify-material .ui-pnotify-title),
  :global([dir=rtl] [ui-pnotify].ui-pnotify-with-icon .pnotify-material .ui-pnotify-text),
  :global([dir=rtl] [ui-pnotify].ui-pnotify-with-icon .pnotify-material .ui-pnotify-confirm) {
    margin-right: 32px;
    margin-left: 0;
  }
  :global([ui-pnotify] .pnotify-material .ui-pnotify-action-bar) {
    margin-top: 20px;
    margin-right: -16px;
    margin-bottom: -16px;
  }
  :global([dir=rtl] [ui-pnotify] .pnotify-material .ui-pnotify-action-bar) {
    margin-left: -16px;
    margin-right: 0;
  }
  :global([ui-pnotify] .pnotify-material-notice) {
    /* https://material.io/color/#!/?view.left=0&view.right=0&primary.color=FFEE58 */
    background-color: #FFEE58;
    border: none;
    color: #000;
  }
  :global([ui-pnotify] .pnotify-material-info) {
    /* https://material.io/color/#!/?view.left=0&view.right=0&primary.color=26C6DA */
    background-color: #26C6DA;
    border: none;
    color: #000;
  }
  :global([ui-pnotify] .pnotify-material-success) {
    /* https://material.io/color/#!/?view.left=0&view.right=0&primary.color=66BB6A */
    background-color: #66BB6A;
    border: none;
    color: #fff;
  }

  :global([ui-pnotify] .pnotify-material-error) {
    /* https://material.io/color/#!/?view.left=0&view.right=0&primary.color=EF5350 */
    background-color: #EF5350;
    border: none;
    color: #fff;
  }
  :global([ui-pnotify] .pnotify-material-icon-notice),
  :global([ui-pnotify] .pnotify-material-icon-info),
  :global([ui-pnotify] .pnotify-material-icon-success),
  :global([ui-pnotify] .pnotify-material-icon-error),
  :global([ui-pnotify] .pnotify-material-icon-closer),
  :global([ui-pnotify] .pnotify-material-icon-sticker) {
    position: relative;
  }
  :global([ui-pnotify] .pnotify-material-icon-closer),
  :global([ui-pnotify] .pnotify-material-icon-sticker) {
    height: 20px;
    width: 20px;
    font-size: 20px;
    line-height: 20px;
    position: relative;
  }
  :global([ui-pnotify] .pnotify-material-icon-notice:after),
  :global([ui-pnotify] .pnotify-material-icon-info:after),
  :global([ui-pnotify] .pnotify-material-icon-success:after),
  :global([ui-pnotify] .pnotify-material-icon-error:after),
  :global([ui-pnotify] .pnotify-material-icon-closer:after),
  :global([ui-pnotify] .pnotify-material-icon-sticker:after) {
    font-family: 'Material Icons';
  }
  :global([ui-pnotify] .pnotify-material-icon-notice:after) {
    content: "announcement";
  }
  :global([ui-pnotify] .pnotify-material-icon-info:after) {
    content: "info";
  }
  :global([ui-pnotify] .pnotify-material-icon-success:after) {
    content: "check_circle";
  }
  :global([ui-pnotify] .pnotify-material-icon-error:after) {
    content: "error";
  }
  :global([ui-pnotify] .pnotify-material-icon-closer),
  :global([ui-pnotify] .pnotify-material-icon-sticker) {
    display: inline-block;
  }
  :global([ui-pnotify] .pnotify-material-icon-closer:after) {
    top: -4px;
    content: "close";
  }
  :global([ui-pnotify] .pnotify-material-icon-sticker:after) {
    top: -5px;
    content: "pause";
  }
  :global([ui-pnotify] .pnotify-material-icon-sticker.pnotify-material-icon-stuck:after) {
    content: "play_arrow";
  }

  :global([ui-pnotify].ui-pnotify .pnotify-material .ui-pnotify-prompt-input) {
    display: block;
    width: 100%;
    margin-bottom: 8px;

    padding: 15px 0 8px;
    background-color: transparent;
    color: inherit;
    border-radius: 0;

    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom-style: solid;
    border-bottom-color: inherit;
    border-bottom-width: 1px;
  }
  :global([ui-pnotify].ui-pnotify .pnotify-material .ui-pnotify-prompt-input:focus) {
    outline: none;
    border-bottom-color: #3F51B5;
    border-bottom-width: 2px;
  }

  /* CSS Material Buttons from https://codepen.io/sebj54/pen/oxluI */
  :global([ui-pnotify].ui-pnotify .pnotify-material .ui-pnotify-action-button) {
    position: relative;

    padding: 0 16px;

    overflow: hidden;

    border-width: 0;
    outline: none;
    border-radius: 2px;

    background-color: transparent;
    color: inherit;

    transition: background-color .3s;

    text-transform: uppercase;
    height: 36px;
    margin: 6px;
    min-width: 64px;
    font-weight: bold;
  }
  :global([ui-pnotify].ui-pnotify .pnotify-material .ui-pnotify-action-button.ui-pnotify-material-primary) {
    /* background-color: #3F51B5; */
    /* color: #ecf0f1; */
    color: #3F51B5;
    /* box-shadow: 0 1px 4px rgba(0, 0, 0, .6); */
  }
  :global([ui-pnotify].ui-pnotify .pnotify-material .ui-pnotify-action-button:hover),
  :global([ui-pnotify].ui-pnotify .pnotify-material .ui-pnotify-action-button:focus) {
    background-color: rgba(0, 0, 0, .12);
    color: inherit;
  }
  :global([ui-pnotify].ui-pnotify .pnotify-material .ui-pnotify-action-button.ui-pnotify-material-primary:hover),
  :global([ui-pnotify].ui-pnotify .pnotify-material .ui-pnotify-action-button.ui-pnotify-material-primary:focus) {
    /* background-color: #303F9F; */
    /* color: #ecf0f1; */
    color: #303F9F;
  }
  :global([ui-pnotify].ui-pnotify .pnotify-material .ui-pnotify-action-button:before) {
    content: "";

    position: absolute;
    top: 50%;
    left: 50%;

    display: block;
    width: 0;
    padding-top: 0;

    border-radius: 100%;

    background-color: rgba(153, 153, 153, .4);

    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  :global([ui-pnotify].ui-pnotify .pnotify-material .ui-pnotify-action-button:active:before) {
    width: 120%;
    padding-top: 120%;

    transition: width .2s ease-out, padding-top .2s ease-out;
  }
</style>
